
header{
  background-color: white;
  margin: 10px;
  padding: 10px;
  height: auto;
  color: #93C6E7;
  font-family: "Arial";
}

header #title{
  margin: 0px;
  padding: 0px;
  font-size: xx-large;
  font-weight: bold;
	color:#93C6E7;
}

header #title:hover{
	color:#6EC1E4;
}

header .line{
  border: solid 1px #93C6E7;
  width: 47%;
}

header #header-line{
  text-align:center;
  font-size:x-large;
  margin-top: 50px;
}

header #left-line{
  float: left;
}

header #right-line{
  float: right;
}

main{
	background-color: white;
	margin: 10px;
	padding: 0 0 20px 0; /* top right bottom left */
  min-height: 400px;
  text-align: center;
  color: #93C6E7;
  font-family: "Arial";
}

main #menu{
  margin: 0px;
  padding: 0px;
  font-size: x-large;
  font-weight: bold;
	margin-bottom: 10px;
}
main #recent-message{
  margin: 0px;
  padding: 0px;
  font-size: small;
  font-style: italic;
  font-family: "Batang";
	margin-bottom: 40px;
}

main .button{
  text-align: center;
	margin: 20px; /* top right bottom left */
}

main #post-form {
	width: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 20px;
	text-align: center;
	border: none;
}

input#post-input{
	border: solid 1px #93C6E7;
  color: #93C6E7;
  padding: 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  width: 60%;
}

input#post-input:focus{
  outline: solid 2px #6EC1E4;
}

table#blog-table{
  margin-left:auto;
  margin-right:auto;
  width: 80%;
	border-top: 2px solid #6EC1E4;
}

table#blog-table th {
	padding: 10px;
	font-weight: bold;
	vertical-align: top;
	border-bottom: 2px solid #6EC1E4;
}

table#blog-table td {
	padding: 10px;
	vertical-align: top;
	border-bottom: 1px solid #93C6E7;
}

td.td-no{
  width: 30px;
}

td.td-date{
  width: 70px;
}

button{
  background-color: #93C6E7;
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  width: 100px;
}

button#post-button{
  margin-top: 15px;
  width: 10%;
  min-width: 100px;
}

button:hover {
  background-color: #6EC1E4;
}

.login-form {
	width: 300px;
	margin-right: auto;
	margin-left: auto;
	padding: 20px;
	text-align: center;
	border: none;
}

.text-field {
	font-size: 14px;
	padding: 10px;
	border: solid 1px #93C6E7;
	width: 260px;
	margin-bottom: 10px;
}

/* calendar */
* {box-sizing: border-box;}
ul {list-style-type: none;}
body {font-family: Verdana, sans-serif;}

.oneYearAgo {
  padding: 0;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  background: #93C6E7;
  text-align: center;
}

.oneYearAgo ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.oneYearAgo ul li {
  color: white;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.oneYearAgo .textOneYearAgo {
  display: grid;
  width: 100%;
  height: 50px;
  align-items: center;
}

.year {
  padding: 0;
  width: 100%;
  height: 80px;
  background: #93C6E7;
  text-align: center;
}

.year ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.year ul li {
  color: white;
  font-size: 25px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.year .nextYear {
  display: grid;
  width: 20%;
  height: 80px;
  align-items: center;
}

.year .textYear {
  display: grid;
  width: 80%;
  height: 80px;
  align-items: center;
}


/* 캘린더 */
.calendar {
  display: flex;
  flex-flow: wrap;
  padding: 0 0 0 0;
}

@media only screen and (min-width : 1224px){
  .calendar li {
    list-style-type: none;
    display: flex;
    width: 19%;
    aspect-ratio: 1;
    text-align: center;
    margin: 0.5%;
    font-size:12px;
    color: #777;
  }
}

@media only screen and (max-width : 1224px){
  .calendar li {
    list-style-type: none;
    display: flex;
    width: 49%;
    max-height: 500px;
    aspect-ratio: 1;
    text-align: center;
    margin: 0.5%;
    font-size:12px;
  }
}


.calendar li.month {
  border: #6EC1E4;
  border-style: solid;
  border-width: 1px;
  background-color: aliceblue;
}

@media only screen and (min-width : 1224px){
  .calendar li.month a {
    font-size: 6vw;
    top: 5%;
    left: 5%;
    position: relative;
    color: #6EC1E4;
    font-style: italic;
    text-shadow: 1px 1px 1px #93C6E7;
  }
}

@media only screen and (max-width : 1224px){
  .calendar li.month a {
    font-size: 9vw;
    top: 5%;
    left: 5%;
    position: relative;
    color: #6EC1E4;
    font-style: italic;
    text-shadow: 1px 1px 1px #93C6E7;
  }
}

.calendar li.imgList {
  display: block;
  border: #93C6E7;
  border-style: solid;
  border-width: 1px;
}

.calendar li.imgList a {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  border-bottom: none;
  outline: none;
  color: #93C6E7;
  text-shadow: 1px 1px 1px #6EC1E4;
}

.calendar li.imgList a img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}

@media only screen and (min-width : 1224px){
  .calendar li.imgList a span {
    position: absolute;
    z-index: 1;
    font-size: 2vw;
  }
}

@media only screen and (max-width : 1224px){
  .calendar li.imgList a span {
    position: absolute;
    z-index: 1;
    font-size: 5vw;
  }
}

.calendar li.imgList a span.caldate {
  top: 10px;
  left: 10px;
}

.calendar li.imgList a span.title {
  width: 100%;
  height: 100%;
  line-height: 1.1;
  left: 0;
  top: 0;
  transition: 0.4s opacity;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 업로드 창 */
.postCalendar {
  padding: 0;
  width: 100%;
  height: 80px;
  background: #93C6E7;
}

.postCalendar ul {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: table;
  text-align: center;
}

.postCalendar ul li {
  display: table-cell;
  color: white;
  font-size: 35px;
  vertical-align: middle;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.postCalendar .upload {
  height: 100%;
}


/* Modal */
.modal {
  position: fixed; /* 고정 위치 */
  top: 50%; /* 화면 중앙에서 수직으로 중앙에 위치 */
  left: 50%; /* 화면 중앙에서 수평으로 중앙에 위치 */
  transform: translate(-50%, -50%); /* 모달의 중심이 화면의 중심에 위치하도록 이동 */
  width: 80%; /* 모달의 너비 */
  height: 70%; /* 모달의 높이 */
  background-color: white; /* 모달의 배경색 */
  border-radius: 5px; /* 모달의 테두리를 둥글게 함 */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* 그림자 효과 추가 */
  z-index: 999; /* 모달이 다른 요소 위에 나타날 수 있도록 z-index 설정 */
  color: #93C6E7;
}
.modal h2{
  margin: 10px;
  padding: 10px;
  height: auto;
  color: #93C6E7;
  font-family: "Arial";
}
.modal .line{
  border: solid 1px #93C6E7;
  width: 90%;
}
.modal p{
  margin: 10px;
  padding: 10px;
  height: auto;
  color: #93C6E7;
  font-family: "Arial";
}
.modal form{
  background-color: white;
  margin: 20px;
  padding: 0 0 20px 0; /* top right bottom left */
  height: 50%;
  text-align: center;
  color: #93C6E7;
  font-family: "Arial";
}
.modal input{
  border: solid 1px #93C6E7;
  color: #93C6E7;
  margin: 15px;
  padding: 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  width: 60%;
}
.modal label {
  border: solid 1px #93C6E7;
  color: white;
  text-align: center;
  background-color: #93C6E7;
  margin: 15px;
  padding: 15px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 12px;
  width: 60%;
}
.modal input#file{
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
  display: none;
}
.modal button#calendar_upload{
  color: white;
  text-align: center;
  background-color: #93C6E7;
  width: 60%;
  margin: 15px;
  padding: 15px;
  float: none;
}
.modal button{
  float: right;
  bottom: 20px;
  background-color: #93C6E7;
  border: none;
  color: white;
  margin: 20px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 12px;
  width: 70px;
}


footer{
  background-color: white;
  text-align: center;
  font-family: "Times New Roman", "serif";
  margin: 10px;
  padding: 10px;
}

footer .line{
  border: solid 1px #93C6E7;
}
